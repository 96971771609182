<script lang="ts" setup>
import {
  IconAlertCircleFilled,
  IconChevronDown,
  IconChevronUp,
  IconCircleCheckFilled,
  IconFileDescription,
  IconX,
} from '@tabler/icons-vue';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import {
  ObAbstractDisclosure,
  ObAbstractDisclosurePanel,
  ObFlexGrid,
  ObFlexGridItem,
  ObLink,
  ObScrollableContainer,
  ObSpace,
  ObSpinner,
  useToaster,
  useZIndex,
} from '../../../../ui-kit';
import { useDownloadsApi } from '../../services';
import { useAccountStore } from '../../../../stores';
import { useDownloadsWidgetStore } from '../../stores/downloads-widget-store';

// TODO: handle loading and errors

const { getDownloadLink } = useDownloadsApi();
const accountStore = useAccountStore();
const downloadsWidgetStore = useDownloadsWidgetStore();
const { d, t } = useI18n();
const toaster = useToaster();

async function downloadItem(id: string) {
  const toast = toaster.create(t('modules.downloadCenter.messages.downloadingFile'), {
    duration: 0,
    loading: true,
    closable: false,
  });

  try {
    const { data } = await getDownloadLink(id);
    window.location.href = data.url;
  } catch (error) {
    toast.content = t('modules.downloadCenter.messages.downloadFileFailed');
    toast.variant = 'danger';
    toast.duration = 3000;
    toast.loading = false;
    toast.closable = true;
  }

  toast.remove();
}

const title = computed(() => {
  if (downloadsWidgetStore.hasPendingItems) {
    return t('modules.downloadCenter.messages.inQueue');
  } else if (downloadsWidgetStore.allCompleted) {
    return t('modules.downloadCenter.messages.readyForDownload');
  } else if (downloadsWidgetStore.allFailed) {
    return t('modules.downloadCenter.messages.allFailed');
  } else {
    return t('common.actions.download');
  }
});

const widgetVisible = computed(() => downloadsWidgetStore.downloads.length > 0);

const { zIndex } = useZIndex({ active: widgetVisible.value });
</script>

<template>
  <Teleport v-if="widgetVisible" to="body">
    <Transition
      appear
      :enter-from-class="$style.transitionEnterFrom"
      :enter-active-class="$style.transitionEnterActive"
      :leave-active-class="$style.transitionLeaveActive"
      :leave-to-class="$style.transitionLeaveTo"
    >
      <div :class="$style.root" role="dialog" tabindex="-1" :style="{ zIndex }">
        <ObAbstractDisclosure
          v-slot="{ expanded }"
          :expanded="downloadsWidgetStore.panelOpen ?? true"
        >
          <header :class="[$style.header, 'color-primary-80']">
            <span> {{ title }} </span>
            <ObSpace spacing="2" align-y="center">
              <button
                type="button"
                :class="$style.button"
                @click="downloadsWidgetStore.togglePanel()"
              >
                <IconChevronUp v-if="expanded" aria-hidden="true" size="18" />
                <IconChevronDown v-else aria-hidden="true" size="18" />
              </button>
              <button type="button" :class="$style.button" @click="downloadsWidgetStore.clear()">
                <IconX aria-hidden="true" size="18" />
              </button>
            </ObSpace>
          </header>
          <ObAbstractDisclosurePanel v-slot="{ rootProps }">
            <ObScrollableContainer light>
              <div v-bind="rootProps">
                <ObFlexGrid direction="column" spacing-y="2" wrap="nowrap" class="pt-2">
                  <ObFlexGridItem
                    v-for="download in downloadsWidgetStore.downloads"
                    :key="download.id"
                  >
                    <div spacing="2" no-wrap :class="$style.item">
                      <ObSpace spacing="4" no-wrap>
                        <IconFileDescription
                          aria-hidden="true"
                          :class="$style.fileDescriptionIcon"
                        />
                        <div :class="$style.fileDetails">
                          <span>{{ download.file_name }}</span>
                          <div class="color-surface-60">
                            {{
                              d(new Date(download.updated_at), {
                                key: 'datetime',
                                timeZone: accountStore.timezone,
                              })
                            }}
                          </div>
                          <div>
                            <ObLink
                              v-if="download.status === 'completed'"
                              v-slot="{ rootProps }"
                              as-child
                            >
                              <button
                                type="button"
                                v-bind="rootProps"
                                @click="downloadItem(download.id)"
                              >
                                <span :class="$style.downloadFile">
                                  {{ t('modules.downloadCenter.actions.downloadFile') }}
                                </span>
                              </button>
                            </ObLink>
                          </div>
                        </div>
                      </ObSpace>
                      <div>
                        <template
                          v-if="
                            download.status === 'initializing' ||
                            download.status === 'processing' ||
                            download.status === 'cancelling'
                          "
                        >
                          <ObSpinner size="18" />
                        </template>
                        <template v-else-if="download.status === 'completed'">
                          <IconCircleCheckFilled color="#A8E1D3" size="18" aria-hidden="true" />
                        </template>
                        <template v-else-if="download.status === 'failed'">
                          <IconAlertCircleFilled color="#f4a9a9" size="18" aria-hidden="true" />
                        </template>
                      </div>
                    </div>
                  </ObFlexGridItem>
                </ObFlexGrid>
              </div>
            </ObScrollableContainer>
          </ObAbstractDisclosurePanel>
        </ObAbstractDisclosure>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss" module>
@use '../../../../ui-kit/styles/shared';
@use '../../../../ui-kit/styles/colors';

.root {
  position: fixed;
  bottom: 12px;
  right: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 18px 0px rgba(2, 17, 72, 0.2); // TODO: use token
  outline: none;
  width: 350px;
  background-color: colors.$white;
  border-radius: 12px;
  border: 1px solid var(--Primary-Surface-16, #d7d9e2);
  max-height: 200px;
  font-size: 12px;
}

.header {
  background-color: #e6effb; // TODO: design token
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  font-size: 14px;
  border-radius: 12px 12px 0px 0px;
}

.item {
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
}

.fileDetails {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.button {
  @include shared.reset-button();
  display: flex;
  color: #021148;

  &:focus-visible {
    outline: 1px solid colors.$hyperlink;
    outline-offset: -1px;
  }
}

.downloadFile {
  font-size: 12px;
}

.fileDescriptionIcon {
  color: #9aa0b6;
}

.transitionEnterFrom,
.transitionLeaveTo {
  opacity: 0.5;
  transform: scale(0.9);
}

.transitionEnterActive,
.transitionLeaveActive {
  transition-property: opacity, transform;
  overflow: hidden;
}

.transitionEnterActive {
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.transitionLeaveActive {
  transition-duration: 0.15s;
  transition-timing-function: ease-in;
}
</style>
